<template>
  <div>
    <SpinnerLoader v-if="broadcastReferenceStatus !== 'success'" :loading="broadcastReferenceStatus" />
    <template v-else-if="broadcastReferenceStatus === 'success' && broadcastReference && broadcastReference[selectedTab]">
      <virtual-scroll
        :items="broadcastReference[selectedTab].items"
        :item-height="28.6"
        class="vscroll table-responsive mb-0 broadcast-reference"
        :class="{ 'with-tabs': tabsCount > 1 }"
      >
        <template slot-scope="{ items }">
          <table class="table table-sm small table-hover table-bordered ws-nowrap sticky-headers sticky-footer">
            <thead>
              <tr>
                <th class="w-30px">№</th>
                <th>
                  <div class="resizable brand">{{ $t('table.brand') }}</div>
                </th>
                <th>
                  <div class="resizable commercial">{{ $t('table.commercial') }}</div>
                </th>
                <th class="text-right w-100px">{{ $t('reports.duration') }}</th>
                <th class="text-right w-100px">{{ $t('reports.exits') }}</th>
                <th class="text-right w-100px">{{ $t('reports.seconds') }}</th>
                <th v-if="with_wgrp" class="text-right w-100px">wGRP</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.order_number">
                <td>{{ item.order_number }}</td>
                <td name="brand">{{ item.brand_name }}</td>
                <td name="commercial">
                  {{ item.commercial_name }}
                  <CoBrandBadge v-if="item.co_branding_discount != null" />
                </td>
                <td class="text-right">{{ item.commercial_duration }}</td>
                <td class="text-right">{{ item.spot_count }}</td>
                <td class="text-right">{{ item.seconds_total }}</td>
                <td v-if="with_wgrp" class="text-right">{{ item.plan_fact_wgrp | toFixedAndSpace }}</td>
              </tr>
            </tbody>
            <tfoot class="font-weight-bold">
              <tr>
                <td colspan="4">{{ $t('booking.total') }}</td>
                <td class="text-right">{{ broadcastReference[selectedTab].totals.spot_count }}</td>
                <td class="text-right">{{ broadcastReference[selectedTab].totals.seconds_total }}</td>
                <td v-if="with_wgrp" class="text-right">{{ broadcastReference[selectedTab].totals.plan_fact_wgrp | toFixedAndSpace }}</td>
              </tr>
            </tfoot>
          </table>
        </template>
      </virtual-scroll>
    </template>
    <div
      v-if="tabsCount > 1"
      class="d-flex px-3 py-2 overflow-auto position-fixed"
      style="left: 0; bottom: 0; right: 0; white-space: nowrap; width: 100vw; z-index: 100"
    >
      <b-button
        v-for="(item, name) in broadcastReference"
        :key="name"
        size="sm"
        :variant="selectedTab === name ? 'primary' : 'link'"
        @click="changeActiveTab(name)"
      >
        {{ name }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import VirtualScroll from '@/components/VirtualScroll';

export default {
  name: 'BroadcastReference',
  components: {
    SpinnerLoader,
    VirtualScroll,
    CoBrandBadge: () => import('@/components/CoBrandBadge.vue'),
  },
  filters: { toFixedAndSpace },
  props: {
    with_wgrp: { type: Boolean },
  },
  data() {
    return {
      selectedTab: 'All',
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      broadcastReference: 'getReportBroadcastReference',
      broadcastReferenceStatus: 'getReportBroadcastReferenceStatus',
    }),
    tabsCount() {
      if (this.broadcastReferenceStatus === 'success' && this.broadcastReference) {
        return Object.keys(this.broadcastReference).length;
      } else return 1;
    },
  },
  watch: {
    broadcastReference() {
      if (this.broadcastReferenceStatus === 'success' && this.broadcastReference) {
        this.selectedTab = Object.keys(this.broadcastReference)[0];
      }
    },
  },
  destroyed() {
    this.$store.commit('clearReportBroadcastReference');
  },
  methods: {
    changeActiveTab(name) {
      this.selectedTab = name;
    },
  },
};
</script>
<style lang="sass">
.w-30px
  width: 30px
.w-100px
  width: 100px

.reports-page div.table-responsive.broadcast-reference
  width: 1100px !important
  margin-left: auto
  margin-right: auto
  height: calc(100vh - 210px)
  &.with-tabs
    height: calc(100vh - 250px)
  .white-space-initial
    white-space: initial

.reports-page .broadcast-reference table.table
  div.resizable
    &.brand,
    &.commercial
      min-width: 200px

  td[name="brand"],
  td[name="commercial"]
    max-width: 200px

@media (min-width: 1671px)
  .reports-page div.table-responsive.broadcast-reference
    height: calc(100vh - 154px)
</style>
